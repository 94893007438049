<template>
 <div>
  <sdPageHeader title="Question">
  </sdPageHeader>
  <Main v-if="errorQuestion">
   <sdCards title="">
    loadingQuestion
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/question')">
       Naar questions
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-if="!errorQuestion">
   <a-form :model="form" layout="vertical" @finish="UpdateQuestion">
    <Upload>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Algemeen">
        <a-skeleton v-if="loadingQuestion" active/>
        <a-row :gutter="16">
         <a-col :sm="12" :xs="24" class="mb-25">
          <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam" name="name">
           <a-input v-model:value="form.name" placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col :sm="12" :xs="24" class="mb-25">
          <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Weergavenaam"
                       name="displayName">
           <a-input v-model:value="form.displayName" placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col :sm="12" :xs="24" class="mb-25">
          <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                       name="inputType">
           <a-select v-model:value="form.inputType" :allowClear="true" class="sDash_fullwidth-select"
                     size="large">
            <a-select-option v-for="(item,index) in QuestionInputTypeOptions" :key="index"
                             :value="item.value" name="inputType">
             {{ item.key }}
            </a-select-option>
           </a-select>
          </a-form-item>
         </a-col>
         <a-col v-if="form.inputType === 'TEXT' || form.inputType === 'INTEGER'" :sm="12" :xs="24" class="mb-25">
          <a-form-item label="Standaard waarde" name="inputDefaultValue">
           <a-input v-model:value="form.inputDefaultValue" placeholder=""/>
          </a-form-item>
         </a-col>
         <a-col v-else-if="form.inputType === 'SELECT'" :sm="12" :xs="24" class="mb-25">
          <a-form-item label="Standaard waarde" name="inputType">
           <a-select v-model:value="form.inputDefaultValue" :allowClear="true" class="sDash_fullwidth-select"
                     size="large">
            <a-select-option v-for="(item,index) in form.inputValues" :key="item + index"
                             :value="form.inputValues[index]" name="inputType">
             {{ form.inputValues[index] }}
            </a-select-option>
           </a-select>
          </a-form-item>
         </a-col>
        </a-row>
        <a-row v-if="form.inputType === 'TEXT' || form.inputType === 'INTEGER'" :gutter="30">
         <a-col :xs="24">
          <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" class="hidden-input" label="Meerkeuze opties"
                       name="inputValues" style="margin-bottom:0px">
           <a-input v-model:value="form.inputValues" placeholder="" style="visibility: hidden;"
                    type="hidden"/>
          </a-form-item>
         </a-col>
        </a-row>
        <a-row v-if="form.inputType === 'SELECT' && form.inputValues.length > 0" :gutter="30">
         <template v-for="(item, index) in form.inputValues" :key="index">
          <a-col :xs="16" class="mb-25">
           <a-form-item :name="['inputValues', index]"
                        :rules="{required: true,message: 'Dit veld is verplicht'}">
            <a-input v-model:value="form.inputValues[index]" :name="['inputValues', index]"
                     type="text"/>
           </a-form-item>
          </a-col>
          <a-col :xs="8"
                 class="mb-25"
                 style="vertical-align: middle;display: flex;justify-content: start;">
           <sdButton class="ml-4" size="small" type="danger"
                     @click.prevent="handleDeleteInputValue(index)">
            <sdFeatherIcons size="14" type="trash"/>
           </sdButton>
          </a-col>
         </template>
        </a-row>
        <a-row v-else-if="form.inputType === 'SELECT'">
         <a-col :xs="24">
          <a-empty :image="simpleImage">
           <template #description>
            <span>Geen meerkeuze opties aangemaakt, druk op plus om opties toe te voegen!</span>
           </template>
          </a-empty>
         </a-col>
        </a-row>
        <a-row v-if="form.inputType === 'SELECT'">
         <a-col :xs="24" class="mt-25">
          <sdButton size="large" type="primary" @click.prevent="handleAddInputValue">
           <sdFeatherIcons size="14" type="plus"/>
           <span>Optie toevoegen</span>
          </sdButton>
         </a-col>
        </a-row>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Upload>
    <sdCards>
     <a-row>
      <a-col :sm="12" :xs="24">
       <div class="sDash_form-action">
        <sdButton class="btn-signin mr-5" html-type="submit" type="light" @click="$router.replace('/question')">
         Terug
        </sdButton>
        <sdButton v-if="$can('update', 'question')" :disabled="loadingPatchQuestion"
                  :loading="loadingPatchQuestion" class="btn-signin" type="primary"> Opslaan
        </sdButton>
       </div>
      </a-col>
     </a-row>
    </sdCards>
   </a-form>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {QuestionInputTypeOptions} from "../../utility/enums";
import {getApiURL} from "../../config/dataService/dataService";
import {Empty} from "ant-design-vue";

export default defineComponent({
 name: 'QuestionEdit',
 components: {
  BasicFormWrapper,
  Main,
  Upload,
 },
 data() {
  return {
   loading: false,
   fileUrl: getApiURL(),
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const form = reactive(computed(() => state.question.question));
  const errorQuestion = computed(() => state.question.errorQuestion);
  const loadingQuestion = computed(() => state.question.loadingQuestion);
  const loadingPatchQuestion = computed(() => state.question.loadingPatchQuestion);
  const UpdateQuestion = () => {
   dispatch('patchQuestion', {
    id: params.id,
    name: form.value.name,
    displayName: form.value.displayName,
    inputValues: form.value.inputValues,
    inputDefaultValue: form.value.inputDefaultValue,
    inputType: form.value.inputType,
   });
  };

  const handleAddInputValue = () => {
   form.value.inputValues.push('');
  }
  const handleDeleteInputValue = (index) => {
   form.value.inputValues.splice(index, 1);
  }

  onMounted(() => {
   dispatch('getQuestion', params.id);
  });

  return {
   form,
   errorQuestion,
   UpdateQuestion,
   loadingPatchQuestion,
   handleAddInputValue,
   handleDeleteInputValue,
   loadingQuestion,
   QuestionInputTypeOptions,
  };
 },
});
</script>
